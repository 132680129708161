import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./Quik.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import QuikPhoto1 from "../../../../res/Photos site/Quik/Quik Graffiti.jpg"
import QuikPhoto2 from "../../../../res/Photos site/Quik/QUIK-Central-Academy-of-Fine-Arts-Beijing-2016-thumbnail_webp-9999x9999.webp"
import QuikPhoto3 from "../../../../res/Photos site/Quik/Quik (2).jpg"
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/quik/portrait.jpg'
import PastPresent1 from "../../../../res/quik/past-present-1.jpg"
import PastPresent2 from "../../../../res/quik/past-present-2.jpg"
import PastPresent3 from "../../../../res/quik/past-present-3.jpg"
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "Quik",
  name: "Quik",
  description:
  "Lin Felton aka Quik was born in 1958 in New York. An active member of the RTW Crew, he worked alongside with Jean-Michel Basquiat, Keith Haring, Seen and Futura and imposed the four letters of his blaze on the trains of the big city. In the 1980’, he was one of the most determined writers to bury the gray railroad under the color of bombs. After studying for three years at the Parsons School of Design, Quik was noticed by Yaki Kornblit, a Dutch art dealer. It is him who incites Quik to come to Europe and it is on the old continent, that he succeeds in affirming himself as an artist. Quik is one of those artists who have infused the European urban scene with the energy of the original graffiti culture. His style deploys a satirical, almost arrogant image. Although his paintings raise fundamental socio- political critiques, they are also strongly autobiographical. It is in this interplay between global and personal issues that Quik's work finds its depth. On the one hand, he shows the pain of black men through his spray paint, but on the other hand he expresses a kind and deeply erotic pleasure by taking an outsider\'s stance towards women. The artist has been exhibited since 1983 at the Yaki Kornblit Gallery in Amsterdam and at the Stellweg/Seguy Gallery in 1984 in New York. More recently Quik was exhibited at the Groninger Museum in 2022 in a group show of New York graffiti artists, at the Artscience Museum in Singapore and at the Danysz gallery in Paris. His work can be found in the permanent collections of museums such as the Studio Museum in Harlem, the Museum of the City of New York and the Groninguer Museum in the Netherlands.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de Quik.",
  photos: [
    { src: PastPresent1, name: "Quik" },
    { src: PastPresent2, name: "Quik" },
    { src: PastPresent3, name: "Quik" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
  citations: []
};

const Quik = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>QUIK <h2 style={{paddingLeft: "16px"}}>(Lin Felton - American)</h2></h1>
          <h3 className={NameWrapper} >Born in 1958</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>

          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Unruly Gallery, Amsterdam, The Netherlands</p>
          <p>- Dirty Pilot, Wayland, MA</p>
          <p>- Grabner Haus, Fürstenfeld, Austria</p>
          <p>- Greenflowers Gallery, Boulogne-Billancourt, France</p>
          <div className={More} id="more">
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- Dirty Pilot, Wayland, MA</p>
          <p>- Lin QUIK Felton “Raw Drawings II”, Green Flowers Art Gallery, Boulogne-Billancourt, France</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- AAA Gallery, Rotterdam, Netherlands</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- Avenir Finance, Paris, France</p>
          <p>- “Blues Paintings”, Galerie Arte Ventuno, Brussels</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- “Urban Dreamers”, Galerie Artcour, Paris VIII, France</p>
          <p>- “Urban Dreamers”, Galerie de Béjarry, Paris IX, France</p>
          <p>- Galerie Onega, Paris, France</p>
          <p>- Casino de Paris, Paris, France</p>
          <p>- “Raw drawings”, Green Flowers Art Gallery, Boulogne-Bilancourt, France</p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- "QUIKworld", NP3, Groningen, The Netherlands</p>
          <p>- Service Compris, Groningen, The Netherlands</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- "Quik" (with catalogue), Gallery Willy Schoots, Eindhoven, The Netherlands</p>
          <br />
          <p style={{fontWeight: "bold"}}>2004</p>
          <p>- Private Domain Henk Pijnenburg, Deurne, The Netherlands</p>
          <p>- Domein 23, Deurne, The Netherland</p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- Galerie Kultur Koln, Cologne, Germany</p>
          <br />
          <p style={{fontWeight: "bold"}}>1999</p>
          <p>- "Quik" (with catalogue), Robinsons Art Gallery, Knokke, Belgium</p>
          <p>- Gallery MAMA, Rotterdam, The Netherlands</p>
          <br />
          <p style={{fontWeight: "bold"}}>1998</p>
          <p>- “Quik & Co”, Groninger Museum, Groningen, The Netherlands</p>
          <br />
          <p style={{fontWeight: "bold"}}>1994</p>
          <p>- Grand Theatre, Groningen, The Netherlands</p>
          <br />
          <p style={{fontWeight: "bold"}}>1993</p>
          <p>- Gallery II Ventuno, Hasselt, Belgium</p>
          <p>- Stadtischen Galerie, Wurzburg, Germany</p>
          <br />
          <p style={{fontWeight: "bold"}}>1992</p>
          <p>- “Quik” (with catalogue), Mannheimer Kunstverein, Mannheim, Germany</p>
          <br />
          <p style={{fontWeight: "bold"}}>1991</p>
          <p>- “Quik” (with catalogue), Private Domain Henk Pijnenburg, Deurne, The Netherlands</p>
          <br />
          <p style={{fontWeight: "bold"}}>1990</p>
          <p>- Suzan Cooper Gallery, Woodstock, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>1988</p>
          <p>- De Boterhal, Hoorn, The Netherlands</p>
          <br />
          <p style={{fontWeight: "bold"}}>1984</p>
          <p>- Galerie Yaki Kornblit, Amsterdam, The Netherlands</p>
          <p>- Stellweg/Seguy Gallery, New York, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- Gallery Art in Progress, Düsseldorf, Germany</p>
          <p>- Gallery Yaki Kornblit, Amsterdam, The Netherlands</p>
          <br />
          <p style={{fontWeight: "bold"}}>GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Art from the Streets, Artscience Museum Singapore | Singapore, Jan 13,2018 - Jun 03,2018</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- The Self-Drawing Hand, Danysz Gallery, Paris 11e | Paris | France, Nov 28,2015 - Dec 23,2015</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Gallery Artemesia, Monaco</p>
          <p>- “Artistes In Transition”, Sochaux Museum, France</p>
          <p>- Rudolph Scharpff Collectie, Stuttgart Kunstmuseum, Germany</p>
          <p>- “Martha Cooper Revisited”, Carmichael Gallery, Culver City, CA</p>
          <p>- “40 Ans, Pressionism”, Collection Alain-Dominique Gallizia, Grimaldi Forum, Monaco</p>
          <p>- “I Miss You”, Krabbendans, Eindhoven, Netherlands</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- “Whole In The Wall”, SWATCH Museum, Geneva, Switzerland</p>
          <p>- “TAG et Graffiti”, Palais de Tokyo, Paris, France (with catalog)</p>
          <p>- “TAG en Direct”, Bache Museale, Paris, France</p>
          <p>- “I love America”, Studio 55 & Pierre Cardin Galerie, Paris, France</p>
          <p>- “Future Development”, Loft Gallery, New York, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- “Les papes du Street Art”, Galerie Matignon Leadouze, Paris, France</p>
          <p>- “From Style Writing to Art”, Galerie Magda Danysz, Paris, France</p>
          <p>- “d’RAW”, SCHUNK*, Glaspaleis, Heerlen, Netherlands</p>
          <p>- “Né dans la Rue”, Foundation Cartier, Paris, France</p>
          <p>- “Le TAG au Grand Palais”, Alain-Dominique Gallizia Collection, Grand Palais, Paris</p>
          <p>- “Whole in the Wall, New York Sessions”, Galerie Helenbeck, New York, NY</p>
          <p>- “Il Est Interdit d’Interdire”, Galerie Helenbeck, Nice, France</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- “Street Art”, Art Curial, Paris. France</p>
          <p>- Collection Henk Pijnenburg, Christies, Amsterdam, The Netherlands</p>
          <p>- “Bandes Dessinees”, Art Curial, Paris, France</p>
          <p>- “Whole in the Wall”, QUIK, SHARP, JON, CRASH & BLADE, Galleries Helenbeck &</p>
          <p>Gismondi, Paris & Nice, France</p>
          <p>- “vanstoftotasse” 7th edition, Asse, Belgium</p>
          <p>- “Aerosol Symphonies”, GO Gallery, Amsterdam, The Netherlands</p>
          <p>- “QUIKSAND”, Kunstencentrum SIGNE, Heerlen, The Netherlands</p>
          <p>- “The Hustle”, POWERHOUSE ARENA, New York, NY</p>
          <p>- Kunstour @ Timmerfabriek, Maastricht, The Netherlands</p>
          <p>- “Wintersalon”, Galerie Arte Ventuno, Brussels, Belgium</p>
          <p>- “SEEN”, Stolen Space Gallery, London, UK</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- Marta-Hereford Museum, Hereford, Germany</p>
          <p>- "L'Art Modeste Sous Les Bombes", Speerstra Collection, Musée Paul Valéry, Séte & Abbaye</p>
          <p>d'Auberive, France</p>
          <p>- CLVII Gallery Showroom, Paris, France</p>
          <p>- Arte Ventuno, Hasselt/Brussels, Belgium</p>
          <p>- Studio Gallizia, ADG Collection, Boulogne-Billancourt, France</p>
          <p>- Wim Reiff Galerie, “Masquerade or a tribute to women”, Maastricht, The Netherlands</p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- Studio Gallizia, Paris, France</p>
          <p>- Willem Speerstra Galerie, "Burners", Paris, France</p>
          <p>- KIDROBOT, "Paint Ball", New York, NY</p>
          <p>- PowerHouse Arena, "NO Sleep Till Brooklyn", Brooklyn, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- Museum Tongerloohuys (with catalogue), “Frozen Fire”, Rosendaal, The Netherlands</p>
          <p>- Kunst Rai, Galerie Willy Schoots, Amsterdam, The Netherlands</p>
          <p>- FIAC, Galerie Hans Mayer, Paris, France</p>
          <p>- ART COLOGNE, Galerie Willy Schoots, Cologne, Germany</p>
          <p>- Galerie HOME, “F@*K 2005” (with catalogue), Amsterdam, The Netherlands</p>
          <br />
          <p style={{fontWeight: "bold"}}>2004</p>
          <p>- sKidrobot, “The DUNNY Show”, New York, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- Sotheby's Amsterdam, “The Writing on the Wall” (with catalogue), The Netherlands</p>
          <p>- New York Urban Experience, "Urbane", New York, NY</p>
          <p>- Soma Gallery, “QUIK, SEEN, CES” (with catalogue), New York, NY</p>
          <p>- Mc Caig-Welles Gallery, “QUIK, SEEN, CES”, New York, NY</p>
          <p>- Upper Playground Gallery, "QUIK, SEEN & CES," San Francisco, CA</p>
          <p>- Marco Art Gallery, New York, NY</p>
          <p>- Max Fish, New York, NY</p>
          <p>- Mc Caig-Welles Gallery, "Battle of Titans”, New York, NY</p>
          <p>- New York Urban Experience Gallery, "Battle of Titans", New York, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- New York Urban Experience Gallery, "Black Rock Coalition", New York, NY</p>
          <p>- New York Urban Experience Gallery, "It's Official", New York, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>2001</p>
          <p></p>
          <p>- Prosper Gallery, Ricky Powell Painted Photographs, New York, NY</p>
          <p>- Upper Playground Gallery, Ricky Powell Painted Photographs, "Funky Dope Maneuvers", San Francisco, CA</p>
          <p>- B's International, Ricky Powell Photographs, Tokyo, Japan</p>
          <p>- New York Urban Experience Gallery, "Renaissance 2001", New York, NY</p>
          <p>- Brooklyn Museum of Art, "Hip Hop Nation", Brooklyn, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>2000</p>
          <p>- Robinson's Art Gallery, Knokke, Belgium</p>
          <p>- Locus Media Gallery, "Rules of Confinement", New York, NY</p>
          <p>- De Kring, "Quik, Shoe & Angel", Amsterdam, The Netherlands</p>
          <p>- Guernsey's Auction House, “Graffiti” (with catalogue), New York, NY</p>
          <p>- Gallerie Henk Pijnenburg, Deurne, The NetherLands</p>
          <p>- "Grand Theatre 20th Anniversary", Groningen, The Netherlands</p>
          <br />
          <p style={{fontWeight: "bold"}}>1999</p>
          <p>- Galerie Henk Pijnenburg, Deurne, Netherlands</p>
          <p>- The Museum of the City of New York, "NY Century", New York, NY</p>
          <p>- Gallery Robinsons, Gent Art Fair, Gent, Belgium</p>
          <br />
          <p style={{fontWeight: "bold"}}>1998</p>
          <p>- “Quik & Co”, Groninger Museum, Groningen, The Netherlands</p>
          <p>- "The Arrow", Stockholm, Sweden</p>
          <p>- Herning Kunstmuseum, "Graffiti – The Writing on the Wall", Herning, Denmark</p>
          <p>- Pijnenburg collection "New York Graffiti", Museum der Stadt Ratingen, Ratingen, Germany</p>
          <br />
          <p style={{fontWeight: "bold"}}>1997</p>
          <p>- Gallery Montana, Copenhagen, Danmark (with leaflet) Travelling exhibition to: Essen, Odense, Munich, Hellerup, The Hague, Karlsruhe, Copenhagen, Berlin, Paris, Aarhus, Reykjavik, Stuttgart, Dresden, London, Oslo, Braunschweig, Lillehammer, Cologne, Esbjerg, Gothenburg, Zürich, Bern, Vienna, Hamburg, Aalborg, Salzburg and Lausanne</p>
          <p>- Kunstpark Ost Forum, Munich, Germany</p>
          <p>- Academical Hospital Radbout, "Transfer to a New World", curator Henk Pijnenburg, Nijmegen, The Netherlands</p>
          <p>- "Urban Aspects", Zürich, Switzerland</p>
          <br />
          <p style={{fontWeight: "bold"}}>1996</p>
          <p>- Stedelijk Museum Roermond "The Venom of the Muse", curator Henk Pijnenburg, The Netherlands</p>
          <p>- "Sub Cult 96", Copenhagen, Danmark</p>
          <p>- Gallery P-Art 96, "Quik & Koor", Darmstadt, Germany</p>
          <p>- AC-BAW Center for the Arts, Mount Vernon, New York</p>
          <p>- Groninger Museum collection, Groningen, The Netherlands</p>
          <p>- Melkweg, “MTV Welcome Home”, Amsterdam, The Netherlands</p>
          <br />
          <p style={{fontWeight: "bold"}}>1995</p>
          <p>- Groninger Museum, "Art of the 80's & 90's", Groningen, The Netherlands</p>
          <p>- Groninger Museum, "Beeldende van de Kunst," The Netherlands</p>
          <br />
          <p style={{fontWeight: "bold"}}>1994</p>
          <p>- Gallery II Ventuno, "Terra Incognita", Hasselt, Belgium</p>
          <p>- Gallery De La Tour, "Bank of Reality", Basel, Switzerland</p>
          <p>- Stads Gallery Librije, “Quik & Lady Pink”, Zwolle, The Netherlands</p>
          <p>- Melkweg, "The Big Awakening," Amsterdam, The Netherlands</p>
          <br />
          <p style={{fontWeight: "bold"}}>1993</p>
          <p>- G.C. Arts, "Quik, Seen & Blade," Antwerp, Belgium</p>
          <p>- Galerie II Ventuno, "Chez Nous," Hasselt, Belgium</p>
          <p>- Dommelhof, "One Size, Many Views," Neerpelt, Belgium</p>
          <p>- Stadts Galerie, "Quik & Lady Pink", Zwolle, The Netherlands</p>
          <br />
          <p style={{fontWeight: "bold"}}>1992</p>
          <p>- Helmond Museum, “Quik & Blade” (with catalogue) The Netherlands</p>
          <p>- Groninger Museum, "Coming from the Subway" (catalogue) Groningen, The Netherlands</p>
          <p>- Gallery Atlantis, Documenta IX, Kassei, Germany</p>
          <p>- Colleen Greco Gallery, Uffern, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>1991</p>
          <p>- Musée National des Monuments Francais, “Graffiti Art” (with catalogue), Paris, France</p>
          <p>- Federal Reserve Board, Post-Graffiti/Fine Art, (with catalogue), Washington DC</p>
          <p>- Real Art Ways, "Hip Hop Nation", Hartford, CT</p>
          <br />
          <p style={{fontWeight: "bold"}}>1990</p>
          <p>- Hans Jurgen Muller/Atlantis, Stuttgart, Germany</p>
          <p>- Wetherholt Gallery, Washington DC</p>
          <br />
          <p style={{fontWeight: "bold"}}>1989</p>
          <p>- “Graffiti Between Anarchy and Gallery”, curator Johannes Stahl (with catalogue): Travelling exhibition: Helmond Museum, Helmond, The Netherlands; Heidelberger Kunstverein,</p>
          <p>- Heidelberg, Germany; Nassauischer Kunstverein Wiesbaden, Wiesbaden, Germany</p>
          <p>- Stadtischen Gallery, "New York Graffiti" (with catalogue), Goppingen, Germany</p>
          <br />
          <p style={{fontWeight: "bold"}}>1988</p>
          <p>- Groninger Museum, Groningen, The Netherlands</p>
          <p>- Museum Helmond, Stedelijk Kunstcollectie, Helmond, The Netherlands</p>
          <p>- Galerie Christ Delaet, "Quik, Seen & Blade," Antwerp, Belgium</p>
          <br />
          <p style={{fontWeight: "bold"}}>1987</p>
          <p>- Wilhelm Hack - Museum, "New York Graffiti" Scharpff collection ( with catalogue), Ludwigshafen, Germany</p>
          <p>- Zack Schuster Gallery, Boca Rotan, FL</p>
          <br />
          <p style={{fontWeight: "bold"}}>1986</p>
          <p>- Pelhalm Art Center, Pelhalm, New York</p>
          <p>- Gallery International 52, New York, NY</p>
          <p>- Tweed Gallery, "Urban Aspects", New York, NY</p>
          <p>- Leopold-Hoesch Museum, “New York Graffiti” (with catalogue), Duren, Germany</p>
          <p>- Denise René/Hans Mayer Gallery, “Fashion Mode”, Düsseldorf, Germany</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- vSigmund Wenger Gallery, “Quik, Seen & Blade”, La Jolla, CA</p>
          <p>- Museum Helmond, “New York Graffiti” (with catalogue), Helmond, The Netherlands</p>
          <br />
          <p style={{fontWeight: "bold"}}>1984</p>
          <p>- Yaki Kornblit Gallery, Cologne Art Fair, Cologne, Germany</p>
          <p>- 121 Gallery, Antwerpen, Belgium</p>
          <p>- Renaissance Society, "Graffiti", Chicago, IL</p>
          <p>- Louisiana Museum, "New York Graffiti" (with catalogue), Humlebaek, Denmark</p>
          <p>- Rijksmuseum Kröller-Müller, “Drawings and sculptures from the collection Adri, Martin and Geertjan Visser” (with catalogue), Otterlo, The Netherlands</p>
          <p>- Zurich Art Fair, Zurich, Switzerland</p>
          <p>- Stedelijk Museum Amsterdam, "The Becht collection" (with catalogue), Amsterdam, The Netherlands</p>
          <p>- Joy Horwich Gallery, Chicago, IL</p>
          <p>- Gallery Het Venster, Rotterdam, The Netherlands</p>
          <p>- Stellweg/Seguy Gallery, "Graffiti Artists”, New York, NY</p>
          <p>- Thomas Gallery, "Classical American Graffiti Writers and High Graffiti Artists" (with catalogue), Munich, Germany</p>
          <p>- Groninger Museum, "Graffiti", Groningen, The Netherlands</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- Boymans van Beuningen Museum, "Graffiti" (with catalogue), Rotterdam, The Netherlands</p>
          <p>- Groninger Museum, Groningen, The Netherlands</p>
          <br />
          <p style={{fontWeight: "bold"}}>1981</p>
          <p>- P.S. 1, "New York/New Wave”, New York, NY</p>
          <p>- Peppermint Lounge, New York, NY</p>
          <p>- Fun Gallery, New York, NY</p>
          <p id="end">- Mudd Club, "Beyond Words", New York, NY</p>
          <br />
          <p style={{fontWeight: "bolder"}}>PUBLIC COLLECTIONS</p>
          <br />
          <p>- Quik (Lin Felton dit) Born in 1958, Queens, New York, United States Live and work in New York</p>
          <p>- The Rudolph Scharpf Collection, Wilhelm-Hack Museum</p>
          <p>- The Martin Wong Collection, New York, NY</p>
          <p>- The Yaki Korenblit Collection, Amsterdam, The Netherlands The Martin Sanders Collection, Amsterdam, The Netherlands Galerie II Ventuno, Hasselt, Belgium</p>
          <p>- Robinsons Art Gallery, Knokke, Belgium</p>
          <p>- The Wildenberg Collection, Naarden, The Netherlands</p>
          <p>- The Dr. Dieter Weber Collection, Wiesbaden Kunstverein, Weisbaden, Germany Marta-Hereford Museum, Hereford, Germany</p>
          <p>- Museum of the City of New York, NY</p>
          <p>- Studio Museum of Harlem, New York, NY</p>
          <p>- The Becht Collection, Amsterdam, The Netherlands</p>
          <p>- The Martin Visser Collection, Bergeijk, The Netherlands</p>
          <p>- Herning Kunstmuseum, Herning, Denmark</p>
          <p>- Groninger Museum, Groningen, The Netherlands</p>
          <p>- The Helmond Museum, Helmond, The Netherlands</p>
          <p>- Galerie Hans Mayer Collection, Dusseldorf, Germany</p>
          <p>- The Henk Pijnenburg Collection, Deurne, The Netherlands</p>
          <br />
          <br />
          </div>
        </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      
      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }
            }
            >
              Contact-us
            </Button>
        </div>
    </div>
  );
};

export default Quik;
// extracted by mini-css-extract-plugin
export var ArtistDescription = "Quik-module--ArtistDescription--HdfF9";
export var ArtistInfos = "Quik-module--ArtistInfos--XxLt+";
export var ButtonWrapper = "Quik-module--ButtonWrapper --TQ9QD";
export var CardWrapper = "Quik-module--CardWrapper--frXze";
export var CarrouselWrapper2 = "Quik-module--CarrouselWrapper2--2k6Rw";
export var Citations = "Quik-module--Citations--hxV43";
export var DescriptionWrapper = "Quik-module--DescriptionWrapper--n3RHb";
export var ImageWrapper = "Quik-module--ImageWrapper--TCnHL";
export var LinkWrapper = "Quik-module--LinkWrapper--81ZA3";
export var MobileProtrait = "Quik-module--MobileProtrait--HcyWu";
export var More = "Quik-module--More--PymCG";
export var MoreButton = "Quik-module--MoreButton--TIxse";
export var NameWrapper = "Quik-module--NameWrapper--s3Qhl";
export var PdpWrapper = "Quik-module--PdpWrapper--K99CA";
export var PhotosWrapper = "Quik-module--PhotosWrapper--Zn24f";
export var ProfilWrapper = "Quik-module--ProfilWrapper--w6BLq";
export var TitleWrapper = "Quik-module--TitleWrapper--mE+UF";
export var Wrapper = "Quik-module--Wrapper--WsVE8";